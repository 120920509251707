import React from "react"
import HeadingM from "../shared/HeadingM"
import ContactOtherBase from "../shared/contact/ContactOther"
import { Target } from "../../utils"

const ContactOther = () => {
  const data = {
    target: "one" as Target,
    tel: "0278-25-4900",
    openingHour: "9:00〜18:00",
    lineId: "@088vzkmk",
    addFriendLink: "lyElKQs",
    fax: "0278-25-4901",
    email: "one@noone.jp",
    instagram: "nico20200401",
  }
  return (
    <>
      <HeadingM title="その他" target="one" />
      <ContactOtherBase {...data} />
    </>
  )
}

export default ContactOther
