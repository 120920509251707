import React from "react"
import ContactForm from "../../components/one/ContactForm"
import ContactOther from "../../components/one/ContactOther"
import HeadingL from "../../components/shared/HeadingL"
import Layout from "../../components/one/Layout"
import SEO from "../../components/Seo"
import { PAGE } from "../../constants"

const contact = () => {
  return (
    <Layout>
      <SEO title={PAGE.title.contact} siteTitle="one" />
      <HeadingL title={PAGE.title.contact} target="one" />
      <ContactForm />
      <ContactOther />
    </Layout>
  )
}

export default contact
